// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/DIESELPOWER.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/HelveticaNeueRoman.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/MYRIADPRO-REGULAR.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;

  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: initial;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;

  background-color: black;
}

@font-face {
  font-family: 'Diesel';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Roman';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
}

@font-face {
  font-family: 'MyriadPro-Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,8JAA8J;EAC9J,mCAAmC;EACnC,kCAAkC;EAClC,eAAe;EACf,mEAAmE;EACnE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,YAAY;;EAEZ,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,+DAAkD;AACpD;;AAEA;EACE,mCAAmC;EACnC,2DAAsD;AACxD;;AAEA;EACE,gCAAgC;EAChC,2DAAqD;AACvD","sourcesContent":["html {\r\n  height: 100%;\r\n\r\n  background-color: black;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  height: initial;\r\n  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);\r\n  height: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\r\n}\r\n\r\n#root {\r\n  height: 100%;\r\n\r\n  background-color: black;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Diesel';\r\n  src: url(fonts/DIESELPOWER.ttf) format('truetype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Helvetica Neue Roman';\r\n  src: url(fonts/HelveticaNeueRoman.woff) format('woff');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'MyriadPro-Regular';\r\n  src: url(fonts/MYRIADPRO-REGULAR.woff) format('woff');\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
