var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { LaunchStatusType, PlatformNext, UndefinedModelDefinition, DefaultStreamerOptions, StreamerStatus, Resolution, streamResolutionConfiguration, } from "@pureweb/platform-sdk";
import { useStreamer, useLaunchRequest, IdleTimeout, VideoStream, System, } from "@pureweb/platform-sdk-react";
import qs from "query-string";
import React, { useEffect, useState, useRef, } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useAsyncEffect from "use-async-effect";
import "./App.css";
import clientConfig from "./client.json";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";
// Views
import { StartView } from "./components/Start";
import { LogInView } from "./components/LogIn";
import { SeriesSelectionView } from "./components/SeriesSelection";
import { BackgroundVideo } from "./components/BackgroundVideo";
import { FadeOut } from "./components/ModalFade";
import { DebugLog } from "./HelperFunctions";
import logger from "./Log";
import { PackageSelectionView } from "./components/PackageSelection";
import { EmbeddedViewPreloads, IntroViewPreloads } from "./components/Preloads";
import { LoadingView } from "./components/Loading";
import { PopupMessage } from "./components/PopupMessage";
import axios from "axios";
var client = clientConfig;
var ClientJson = /** @class */ (function () {
    function ClientJson() {
    }
    return ClientJson;
}());
var ClientOptions = /** @class */ (function () {
    function ClientOptions() {
        // Overridable streamer options
        this.ForceRelay = false;
    }
    ClientOptions.prototype.isValid = function () {
        if (!this.ProjectId) {
            return false;
        }
        if (!this.ModelId) {
            return false;
        }
        return true;
    };
    return ClientOptions;
}());
var LocalStorageKeys = /** @class */ (function () {
    function LocalStorageKeys() {
        this.id = "applicantid";
        this.seriesSelection = "applicantseries";
        this.packageSelection = "applicantpackage";
        this.vehicleDataExists = "vehicledata_exists";
        this.usePreProd = "usePreProd";
    }
    return LocalStorageKeys;
}());
var localStorageKeys = new LocalStorageKeys();
var dataMessageEmitJson = /** @class */ (function () {
    function dataMessageEmitJson(id, series, packageData, vehicleData) {
        this.idData = "";
        this.seriesData = "";
        this.packageData = "";
        this.vehicleDataExists = false;
        this.idData = id;
        this.seriesData = series;
        this.packageData = packageData;
        this.vehicleDataExists = vehicleData;
    }
    return dataMessageEmitJson;
}());
var VehicleData = /** @class */ (function () {
    function VehicleData() {
        this.savedVehicles = [];
    }
    return VehicleData;
}());
var LogInJWT = /** @class */ (function () {
    function LogInJWT() {
        this.ApplicantId = "";
        this.exp = 0;
    }
    return LogInJWT;
}());
localStorage.setItem(localStorageKeys.usePreProd, "f");
var IntroView = function (props) {
    var _a = useState(false), isStarted = _a[0], setStarted = _a[1];
    var _b = useState(false), isSignedIn = _b[0], setSignedIn = _b[1];
    var reactPlugin = new ReactPlugin();
    // var appInsights = new ApplicationInsights({
    //   config: {
    //     connectionString:
    //       "InstrumentationKey=f40a8acf-9a54-4ece-86ad-b6c4eb9ba85e;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=387d0a5b-a01c-4b06-a043-ba8207661f99",
    //     extensions: [reactPlugin],
    //   },
    // });
    // appInsights.loadAppInsights();
    // appInsights.trackPageView();
    var cookies = new Cookies();
    var start = function () { return __awaiter(void 0, void 0, void 0, function () {
        var gtdToken, decoded, now;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStarted(true);
                    gtdToken = cookies.get("user");
                    if (gtdToken === null || gtdToken === undefined) {
                        return [2 /*return*/];
                    }
                    decoded = jwtDecode(gtdToken);
                    // appInsights.trackEvent({
                    //   name: "Decoded ApplicantId",
                    //   properties: { id: decoded.ApplicantId },
                    // });
                    if (!decoded.exp)
                        return [2 /*return*/];
                    now = Date.now();
                    if (!(decoded.exp * 1000 > now)) return [3 /*break*/, 2];
                    setSignedIn(true);
                    localStorage.setItem(localStorageKeys.id, decoded.ApplicantId);
                    return [4 /*yield*/, getVehicleData()];
                case 1:
                    _a.sent();
                    setTimeout(props.StartLaunch, 1000);
                    return [3 /*break*/, 3];
                case 2:
                    DebugLog("Token Expired");
                    cookies.remove("user");
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getVehicleData = function (tokenData) {
        if (tokenData === void 0) { tokenData = ""; }
        return __awaiter(void 0, void 0, void 0, function () {
            var appId, authToken, options, url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        appId = localStorage.getItem(localStorageKeys.id);
                        authToken = cookies.get("user") === undefined ? tokenData : cookies.get("user");
                        options = {
                            method: "POST",
                            headers: {
                                // "Access-Control-Allow-Headers": "Content-Type",
                                // "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                            },
                            data: { _ID_Applicant: appId },
                            signal: AbortSignal.timeout(10000),
                        };
                        url = "https://17avbi.apps.connect.claris.com/api/webhook/v1/retrieve_configuration/catch";
                        return [4 /*yield*/, axios(url, options)
                                .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                                var data, vehicleData;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            localStorage.setItem(localStorageKeys.vehicleDataExists, "false");
                                            DebugLog("**TEST: " + res.status.toString());
                                            if (res.status === 204) {
                                                DebugLog("NO Vehicle Data");
                                                return [2 /*return*/, false];
                                            }
                                            else if (res.status === 500 || res.status === 503) {
                                                DebugLog("Error in vehicle data collection. Continueing w/ no vehicle data");
                                                return [2 /*return*/, false];
                                            }
                                            else if (res.status === 200) {
                                                DebugLog("Vehicle Data EXISTS");
                                                localStorage.setItem(localStorageKeys.vehicleDataExists, "true");
                                                return [2 /*return*/, true];
                                            }
                                            return [4 /*yield*/, res.data];
                                        case 1:
                                            data = _a.sent();
                                            vehicleData = data;
                                            if (vehicleData.savedVehicles.length > 0) {
                                                DebugLog("Vehicle Data EXISTS");
                                                localStorage.setItem(localStorageKeys.vehicleDataExists, "true");
                                                return [2 /*return*/, true];
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); })
                                .catch(function (error) {
                                DebugLog("Error in VehicleData_GET");
                                DebugLog("ErrorMessage: " + error.message);
                                DebugLog("ErrorCode: " + error.code);
                                if (error.message == "canceled") {
                                    localStorage.setItem(localStorageKeys.vehicleDataExists, "false");
                                    localStorage.removeItem(localStorageKeys.seriesSelection);
                                    localStorage.removeItem(localStorageKeys.packageSelection);
                                    DebugLog("Data Request Timed Out");
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, false];
                }
            });
        });
    };
    var postLoginLogic = function (tokenData) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVehicleData(tokenData)];
                case 1:
                    _a.sent();
                    setTimeout(function () { return setSignedIn(true); }, 800);
                    setTimeout(props.StartLaunch, 1400);
                    return [2 /*return*/];
            }
        });
    }); };
    var logIn = function (u, p) { return __awaiter(void 0, void 0, void 0, function () {
        var userName, password, logInResult, jsonRequest, options, url, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userName = u;
                    password = p;
                    logInResult = "";
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    jsonRequest = {
                        email: userName,
                        password: password,
                    };
                    options = {
                        method: "POST",
                        headers: {
                            "Access-Control-Allow-Headers": "Content-Type",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "POST",
                            "Content-Type": "application/json",
                        },
                        data: JSON.stringify(jsonRequest),
                        signal: AbortSignal.timeout(10000),
                    };
                    url = process.env.REACT_APP_API + "/Login";
                    return [4 /*yield*/, axios(url, options)
                            .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                            var tokenData, success, decoded;
                            return __generator(this, function (_a) {
                                tokenData = res.data.token;
                                success = res.data.success;
                                if (success) {
                                    decoded = jwtDecode(tokenData);
                                    cookies.set("user", tokenData, {
                                        path: "/",
                                        sameSite: "strict",
                                        secure: false,
                                    });
                                    // appInsights.trackEvent({
                                    //   name: "Decoded ApplicantId",
                                    //   properties: { id: decoded.ApplicantId },
                                    // });
                                    localStorage.setItem(localStorageKeys.id, decoded.ApplicantId);
                                    logInResult = success ? "success" : "";
                                    postLoginLogic(tokenData);
                                }
                                else {
                                }
                                return [2 /*return*/];
                            });
                        }); })
                            .catch(function (error) {
                            if (error.message == "canceled") {
                                DebugLog("Request Timed Out");
                                logInResult = "timeout";
                            }
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/, logInResult];
                case 3:
                    err_1 = _a.sent();
                    logger.error(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, logInResult];
            }
        });
    }); };
    return (_jsxs("div", __assign({ style: { height: "100%", display: "flex", justifyContent: "center" } }, { children: [_jsx(IntroViewPreloads, {}), _jsx(EmbeddedViewPreloads, {}), _jsx(BackgroundVideo, {}), !isStarted && _jsx(StartView, { Start: start }), !isSignedIn && isStarted && _jsx(LogInView, { LogIn: logIn }), isSignedIn && _jsx(FadeOut, {})] })));
};
var EmbeddedView = function (props) {
    var videoRef = useRef(null);
    var handle = useFullScreenHandle();
    // Fullscreen API presently supported on iPad, but not iPhone or iPod
    var isIPhone = System.Browser().os === "iOS" &&
        !window.navigator.userAgent.includes("iPad");
    var _a = useState(""), seriesSelected = _a[0], setSeriesSelected = _a[1];
    var _b = useState(""), packageSelected = _b[0], setPackageSelected = _b[1];
    var _c = useState(false), doneLoading = _c[0], setDoneLoading = _c[1];
    var firstLoad = useRef(true);
    if (props.ResetSelections) {
        if (seriesSelected !== "") {
            DebugLog("Resetting Selection");
            localStorage.removeItem(localStorageKeys.seriesSelection);
            localStorage.removeItem(localStorageKeys.packageSelection);
            setSeriesSelected("");
            setPackageSelected("");
        }
    }
    else {
        var localSeries = localStorage.getItem(localStorageKeys.seriesSelection);
        var localPackage = localStorage.getItem(localStorageKeys.packageSelection);
        if ((localSeries !== null && seriesSelected !== localSeries) ||
            (localSeries === null && props.ExistingVehicleData)) {
            var newSeries = props.ExistingVehicleData
                ? "SeeData"
                : localSeries;
            localStorage.setItem(localStorageKeys.seriesSelection, newSeries);
            setSeriesSelected(newSeries);
        }
        if ((localPackage !== null && packageSelected !== localPackage) ||
            (localPackage === null && props.ExistingVehicleData)) {
            var newPackage = props.ExistingVehicleData
                ? "SeeData"
                : localPackage;
            localStorage.setItem(localStorageKeys.packageSelection, newPackage);
            setPackageSelected(newPackage);
        }
    }
    var selectSeries = function (m) { return __awaiter(void 0, void 0, void 0, function () {
        var selection;
        return __generator(this, function (_a) {
            selection = m;
            if (selection === "Special Edition") {
                selectPackage("No Package");
            }
            localStorage.setItem(localStorageKeys.seriesSelection, selection);
            setSeriesSelected(selection);
            return [2 /*return*/, selection];
        });
    }); };
    var selectPackage = function (m) { return __awaiter(void 0, void 0, void 0, function () {
        var selection;
        return __generator(this, function (_a) {
            if (m === "back") {
                DebugLog("Setting series to empty");
                setSeriesSelected("");
                localStorage.removeItem(localStorageKeys.seriesSelection);
                return [2 /*return*/, ""];
            }
            selection = m;
            localStorage.setItem(localStorageKeys.packageSelection, selection);
            setPackageSelected(selection);
            props.InputEmitter.EmitUIInteraction("BackToConfig");
            return [2 /*return*/, selection];
        });
    }); };
    if (doneLoading !== props.LoadingDone) {
        var ms = !props.LoadingDone ? 100 : 600;
        setTimeout(function () {
            setDoneLoading(props.LoadingDone);
            firstLoad.current = false;
        }, ms);
    }
    return (_jsxs("div", __assign({ style: { height: "100%" } }, { children: [_jsx(IntroViewPreloads, {}), _jsx(EmbeddedViewPreloads, {}), _jsxs(FullScreen, __assign({ handle: handle }, { children: [!seriesSelected && _jsx(SeriesSelectionView, { SeriesSelect: selectSeries }), seriesSelected && !packageSelected && (_jsx(PackageSelectionView, { PackageSelect: selectPackage })), _jsx(IdleTimeout, { Status: props.StreamerStatus, WarningThreshold: 240, ExitThreshold: 120, WarningCallback: handle.exit, ExitCallback: function () { return window.location.reload(); } }), seriesSelected && packageSelected && !doneLoading && (_jsx(LoadingView, { LaunchRequestStatus: props.LaunchRequestStatus, StreamerStatus: props.StreamerStatus, LoadingDone: props.LoadingDone })), seriesSelected && packageSelected && (_jsx(VideoStream, { VideoRef: videoRef, Emitter: props.InputEmitter, Stream: props.VideoStream, UseNativeTouchEvents: props.UseNativeTouchEvents, UsePointerLock: props.UsePointerLock, PointerLockRelease: props.PointerLockRelease, Resolution: streamResolutionConfiguration(props.Resolution) }))] }))] })));
};
var isMobile = function () {
    var hasTouchscreen = "ontouchstart" in window;
    //alert(hasTouchscreen ? "has touchscreen" : "doesn't have touchscreen");
    return hasTouchscreen;
};
// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
var audio = new Audio();
audio.autoplay = true;
audio.volume = 0.5;
// Parse query parameters
var query = qs.parse(window.location.search);
var clientOptions = new ClientOptions();
clientOptions.LaunchType = (_a = query["launchType"]) !== null && _a !== void 0 ? _a : client.launchType;
if (query["collaboration"] && query["collaboration"] === "true") {
    clientOptions.LaunchType = "local";
}
clientOptions.Endpoint = (_b = query["endpoint"]) !== null && _b !== void 0 ? _b : client.endpoint;
clientOptions.ProjectId = (_c = query["projectId"]) !== null && _c !== void 0 ? _c : client.projectId;
clientOptions.ModelId = isMobile()
    ? process.env.REACT_APP_MOBILE_MODEL_ID
    : process.env.REACT_APP_MODEL_ID;
clientOptions.Version = (_d = query["version"]) !== null && _d !== void 0 ? _d : client.version;
clientOptions.EnvironmentId =
    (_e = query["environmentId"]) !== null && _e !== void 0 ? _e : client.environmentId;
clientOptions.Resolution =
    (_f = query["resolution"]) !== null && _f !== void 0 ? _f : client.resolution;
clientOptions.Resolution = (_g = clientOptions.Resolution) !== null && _g !== void 0 ? _g : Resolution.fhd;
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
    (_h = (query["usePointerLock"] === undefined
        ? client.usePointerLock
        : query["usePointerLock"] === "true")) !== null && _h !== void 0 ? _h : true;
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
    (_j = (query["pointerLockRelease"] === undefined
        ? client.pointerLockRelease
        : query["pointerLockRelease"] === "true")) !== null && _j !== void 0 ? _j : true;
clientOptions.ForceRelay = (_k = query["forceRelay"] !== undefined) !== null && _k !== void 0 ? _k : false;
clientOptions.UseNativeTouchEvents =
    (_l = (query["useNativeTouchEvents"] === undefined
        ? client.useNativeTouchEvents
        : query["useNativeTouchEvents"] === "true")) !== null && _l !== void 0 ? _l : false;
clientOptions.UseMic =
    (_m = (query["useMic"] === undefined
        ? client.useMic
        : query["useMic"] === "true")) !== null && _m !== void 0 ? _m : false;
// Initialize platform reference
var platform = new PlatformNext();
platform.initialize({
    endpoint: clientOptions.Endpoint || "https://api.pureweb.io",
});
var App = function () {
    var _a = useState(false), modelDefinitionUnavailable = _a[0], setModelDefinitionUnavailable = _a[1];
    var _b = useState(new UndefinedModelDefinition()), modelDefinition = _b[0], setModelDefinition = _b[1];
    var _c = useState(), availableModels = _c[0], setAvailableModels = _c[1];
    var _d = useState(), launchRequestError = _d[0], setLaunchRequestError = _d[1];
    var _e = useState(false), resetSelections = _e[0], setResetSelections = _e[1];
    var streamerOptions = DefaultStreamerOptions;
    var _f = useState(false), usePopup = _f[0], setUsePopup = _f[1];
    var delayAfterPopup = useRef(false);
    var delayAfterStreamLaunch = useRef(false);
    var cookies = new Cookies();
    var reactPlugin = new ReactPlugin();
    // var appInsights = new ApplicationInsights({
    //   config: {
    //     connectionString:
    //       "InstrumentationKey=f40a8acf-9a54-4ece-86ad-b6c4eb9ba85e;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=387d0a5b-a01c-4b06-a043-ba8207661f99",
    //     extensions: [reactPlugin],
    //   },
    // });
    // appInsights.loadAppInsights();
    // appInsights.trackPageView();
    var _g = useState(false), ticking = _g[0], setTicking = _g[1], _h = useState(0), count = _h[0], setCount = _h[1];
    useEffect(function () {
        var timer = setTimeout(function () {
            if (ticking) {
                var applicantId = localStorage.getItem(localStorageKeys.id);
                var applicantSeries = localStorage.getItem(localStorageKeys.seriesSelection);
                var applicantPackage = localStorage.getItem(localStorageKeys.packageSelection);
                var vehicleData = localStorage.getItem(localStorageKeys.vehicleDataExists) === "true"
                    ? true
                    : false;
                if (applicantId &&
                    applicantSeries &&
                    applicantPackage &&
                    applicantId !== "" &&
                    applicantSeries !== "" &&
                    applicantPackage !== "") {
                    var userDataMessage = new dataMessageEmitJson(applicantId, applicantSeries, applicantPackage, vehicleData);
                    DebugLog("**Sending User Data");
                    emitter.EmitUIInteraction(userDataMessage);
                }
                setCount(count + 1);
            }
        }, 3e3);
        return function () { return clearTimeout(timer); };
    }, [count, ticking]);
    var getStreamToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var logInToken, authString, options, tokenData_1, url, models, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!clientOptions.ProjectId) return [3 /*break*/, 8];
                    DebugLog("Getting Stream Access");
                    logger.info("Initializing available models: " + clientOptions.ProjectId);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    logInToken = cookies.get("user");
                    if (logInToken === undefined || logInToken === null) {
                        DebugLog("LOGIN TOKEN WAS NULL");
                        return [2 /*return*/];
                    }
                    authString = "Bearer ".concat(logInToken);
                    options = {
                        method: "GET",
                        headers: {
                            Authorization: authString,
                        },
                    };
                    url = process.env.REACT_APP_API + "/PureWeb/Token";
                    return [4 /*yield*/, fetch(url, options)
                            .then(function (res) { return res.json(); })
                            .then(function (data) {
                            tokenData_1 = data.token;
                        })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, platform.useSessionCredentials(clientOptions.ProjectId, tokenData_1, clientOptions.EnvironmentId)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, platform.connect()];
                case 4:
                    _a.sent();
                    logger.info("Agent Connected: " + platform.agent.id);
                    streamerOptions.iceServers = platform.agent.serviceCredentials
                        .iceServers;
                    streamerOptions.forceRelay = clientOptions.ForceRelay;
                    streamerOptions.audio.useMic = clientOptions.UseMic;
                    return [4 /*yield*/, platform.getModels()];
                case 5:
                    models = _a.sent();
                    setAvailableModels(models);
                    return [3 /*break*/, 7];
                case 6:
                    err_2 = _a.sent();
                    logger.error(err_2);
                    return [3 /*break*/, 7];
                case 7: return [3 /*break*/, 9];
                case 8:
                    DebugLog("No ProjectId on found");
                    _a.label = 9;
                case 9: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (availableModels === null || availableModels === void 0 ? void 0 : availableModels.length) {
            var selectedModels = availableModels.filter(function (model) {
                // TODO:P REMOVE WHEN PRODUCTION/SIGN-IN TESTING IS STARTED
                // if (!isMobile()) {
                //   clientOptions.ModelId = process.env.REACT_APP_MODEL_ID;
                // }
                if (clientOptions.ModelId === model.id) {
                    // If there is a version specified and we encounter it
                    if (clientOptions.Version &&
                        clientOptions.Version === model.version) {
                        return true;
                    }
                    // If there is no version specified and we find the primary version
                    if (!clientOptions.Version && model.active) {
                        return true;
                    }
                }
                return false;
            });
            if (selectedModels === null || selectedModels === void 0 ? void 0 : selectedModels.length) {
                setModelDefinition(selectedModels[0]);
            }
            else {
                setModelDefinitionUnavailable(true);
            }
        }
    }, [availableModels]);
    var launchRequestOptions = {
        virtualizationProviderOverride: query["virtualizationProviderOverride"],
    };
    var _j = useLaunchRequest(platform, modelDefinition, launchRequestOptions), status = _j[0], launchRequest = _j[1], queueLaunchRequest = _j[2];
    var _k = useStreamer(platform, launchRequest, streamerOptions), streamerStatus = _k[0], emitter = _k[1], videoStream = _k[2], audioStream = _k[3], messageSubject = _k[4];
    var _l = useState(false), loading = _l[0], setLoading = _l[1];
    var _m = useState(false), doneLoading = _m[0], setDoneLoading = _m[1];
    var doneLoadingRef = useRef(false);
    var earlyRefreshDoneRef = useRef(false);
    useEffect(function () {
        if (streamerStatus === StreamerStatus.Failed) {
            platform.disconnect();
        }
    }, [streamerStatus]);
    if (audioStream) {
        audio.srcObject = audioStream;
    }
    var launchStream = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!delayAfterStreamLaunch.current) return [3 /*break*/, 2];
                    delayAfterStreamLaunch.current = true;
                    if (!loading && !doneLoadingRef.current)
                        setLoading(true);
                    return [4 /*yield*/, getStreamToken()];
                case 1:
                    _a.sent();
                    audio.load();
                    setTimeout(function () {
                        delayAfterStreamLaunch.current = false;
                    }, 10000);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useAsyncEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(clientOptions.LaunchType !== "local" && modelDefinition.id)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, queueLaunchRequest()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_3 = _a.sent();
                    setLaunchRequestError(err_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [modelDefinition]);
    // Log status messages
    useEffect(function () {
        logger.info("Status", status, streamerStatus);
    }, [status, streamerStatus]);
    // Subscribe to game messages
    useEffect(function () {
        var subscription = messageSubject.subscribe(function (value) {
            logger.info("Message: " + value);
            var messageObj = JSON.parse(value);
            var message = messageObj.message
                ? messageObj.message
                : messageObj.request;
            DebugLog("PSMessage: " + message);
            if (messageObj.hasOwnProperty("alert")) {
                alert(messageObj.alert);
            }
            if (messageObj.hasOwnProperty("message") &&
                messageObj.message === "StreamReady") {
                DebugLog('Sending "Start" Message to UE5');
                // emitter.EmitUIInteraction("StartInteraction");
                // doneLoadingRef.current = true;
                // setDoneLoading(true);
            }
            if (messageObj.hasOwnProperty("request")) {
                if (messageObj.request === "SendData" &&
                    (localStorage.getItem(localStorageKeys.vehicleDataExists) ===
                        "true" ||
                        localStorage.getItem(localStorageKeys.packageSelection) !== null)) {
                    var applicantId = localStorage.getItem(localStorageKeys.id);
                    var applicantSeries = localStorage.getItem(localStorageKeys.seriesSelection);
                    var applicantPackage = localStorage.getItem(localStorageKeys.packageSelection);
                    var vehicleData = localStorage.getItem(localStorageKeys.vehicleDataExists) ===
                        "true"
                        ? true
                        : false;
                    if (applicantId &&
                        applicantSeries &&
                        applicantPackage &&
                        applicantId !== "" &&
                        applicantSeries !== "" &&
                        applicantPackage !== "") {
                        DebugLog('Sending "Data" Message to UE5');
                        // appInsights.trackMetric({
                        //   name: "ApplicantId sent to Config",
                        //   value: applicantId,
                        // });
                        var userDataMessage = new dataMessageEmitJson(applicantId, applicantSeries, applicantPackage, vehicleData);
                        emitter.EmitUIInteraction(userDataMessage);
                    }
                }
                else if (messageObj.request === "BackToSeries") {
                    localStorage.removeItem(localStorageKeys.vehicleDataExists);
                    setResetSelections(true);
                    emitter.EmitUIInteraction("Going back to series");
                }
                else if (messageObj.request === "LogOut") {
                    cookies.remove("user");
                    localStorage.clear();
                    window.location.reload();
                }
                else if (messageObj.request === "FullScreen") {
                    if (document.fullscreenElement &&
                        document.fullscreenElement !== null) {
                        document.exitFullscreen();
                    }
                    else {
                        document.body.requestFullscreen();
                    }
                }
                else if (messageObj.request === "StopDataTransfer") {
                    DebugLog("** Stopping Ticking");
                    setTicking(false);
                }
            }
        }, function (err) {
            logger.error(err);
        });
        return function () {
            subscription.unsubscribe();
        };
    }, [messageSubject]);
    // Loading -> Stream trigger setup
    var _o = useState(false), observerSetup = _o[0], setObserverSetup = _o[1];
    var SetupVideoMutationObserver = function () {
        var element = document.querySelector("video");
        if (element === null) {
            DebugLog("element is NOT valid");
            setObserverSetup(false);
            return;
        }
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === "attributes" &&
                    mutation.attributeName === "muted" &&
                    !doneLoading) {
                    // delay so that config has time to update car
                    setTicking(true);
                    setTimeout(function () {
                        doneLoadingRef.current = true;
                        setDoneLoading(true);
                        setTimeout(function () {
                            setTicking(false);
                        }, 60e3);
                    }, 5e3);
                }
            });
        });
        setTimeout(function () {
            observer.observe(element, {
                attributes: true,
            });
        }, 1e3);
    };
    if (!observerSetup && loading) {
        setObserverSetup(true);
        setTimeout(function () {
            SetupVideoMutationObserver();
            console.log("**Calling Setup**");
        }, 3e3);
    }
    var tryErrorPopup = function (autoClosePopup) {
        if (autoClosePopup === void 0) { autoClosePopup = false; }
        if (!usePopup && !delayAfterPopup.current)
            setUsePopup(true);
        if (autoClosePopup) {
            setTimeout(function () {
                closeErrorPopup();
            }, 10000);
        }
    };
    var closeErrorPopup = function () {
        // create delay til popup can be used again
        delayAfterPopup.current = true;
        setTimeout(function () {
            delayAfterPopup.current = false;
        }, 9000);
        if (usePopup)
            setUsePopup(false);
    };
    var header = useRef("");
    var body = useRef("");
    var useRefresh = useRef(false);
    var useClose = useRef(true);
    // Notify user of missing or errors in configuration
    if (!clientOptions.isValid() && clientOptions.LaunchType !== "local") {
        console.error("Client has one or more configuration errors. Consult the ReadMe for details on how to configure the client template.");
        header.current = "Client has one or more configuration errors.";
        body.current = "Refresh or try again later.";
        useRefresh.current = true;
        useClose.current = true;
        tryErrorPopup();
    }
    else if (modelDefinitionUnavailable &&
        clientOptions.LaunchType !== "local") {
        console.error("The model requested does not exist.");
        header.current = "The content requested does not currently exist.";
        body.current = "Please try again later.";
        useRefresh.current = false;
        useClose.current = true;
        tryErrorPopup();
    }
    else if (launchRequestError && !doneLoading) {
        var errorMessage = launchRequestError;
        console.error("DEVELOPER: There was an error with the launch request: " + errorMessage);
        header.current = "There was an error with the launch request.";
        body.current =
            "It appears the requested content is currently not online as per your set schedule. Please refresh or try again later if it should be available.";
        useRefresh.current = true;
        useClose.current = true;
        tryErrorPopup();
    }
    else if (streamerStatus === StreamerStatus.Disconnected) {
        console.warn("Disconnnected from stream...ATTEMPTING RECONNECT");
    }
    else if (streamerStatus === StreamerStatus.Failed ||
        status.status === LaunchStatusType.Unavailable ||
        status.status === LaunchStatusType.Error) {
        console.warn("Relaunching Configurator due to Failure during load");
        launchStream();
        header.current = "There was an error with the launch request.";
        body.current = "Configurator is now restarting...";
        useRefresh.current = false;
        useClose.current = true;
        tryErrorPopup(true);
    }
    else if (streamerStatus === StreamerStatus.NotSupported) {
        header.current =
            "Your browser does not support the necessary WebRTC capabilities.";
        body.current = "Please try using a different internet browser.";
        useRefresh.current = false;
        useClose.current = true;
        tryErrorPopup();
    }
    else if (streamerStatus === StreamerStatus.Withdrawn) {
        header.current = "Streamer contribution withdrawn.";
        body.current = "Please try again later.";
        useRefresh.current = false;
        useClose.current = true;
        tryErrorPopup();
    }
    else if ((streamerStatus === StreamerStatus.Connected ||
        streamerStatus === StreamerStatus.Completed) &&
        doneLoading) {
        closeErrorPopup();
    }
    if (loading || streamerStatus === StreamerStatus.Disconnected) {
        var vehicleDataExists = localStorage.getItem(localStorageKeys.vehicleDataExists) === "true"
            ? true
            : false;
        setTimeout(function () {
            setResetSelections(false);
        }, 100);
        return (_jsxs(_Fragment, { children: [usePopup && (_jsx(PopupMessage, { Header: header.current, Body: body.current, useRefreshButton: useRefresh.current, useCloseButton: useClose.current, OnClose: closeErrorPopup })), _jsx(EmbeddedView, { VideoStream: videoStream, StreamerStatus: streamerStatus, LaunchRequestStatus: status, InputEmitter: emitter, UseNativeTouchEvents: clientOptions.UseNativeTouchEvents, UsePointerLock: clientOptions.UsePointerLock, PointerLockRelease: clientOptions.PointerLockRelease, Resolution: clientOptions.Resolution, LoadingDone: doneLoading, ResetSelections: resetSelections, ExistingVehicleData: vehicleDataExists })] }));
    }
    else {
        return (_jsxs(_Fragment, { children: [usePopup && (_jsx(PopupMessage, { Header: header.current, Body: body.current, useRefreshButton: useRefresh.current, useCloseButton: useClose.current, OnClose: closeErrorPopup })), _jsx(IntroView, { StartLaunch: launchStream })] }));
    }
};
var AppWrapper = function () {
    return _jsx(App, {});
    // return System.IsBrowserSupported() ? (
    //   <App />
    // ) : (
    //   <div className="ui red segment center aligned basic">
    //     <h2 className="header">Your browser is currently unsupported</h2>
    //   </div>
    // );
};
export default AppWrapper;
